// components/AlignMyAI.js
import { ArrowDownTrayIcon, ArrowUpTrayIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import { Circle, Layer, Line, Stage, Text } from 'react-konva';
import { Button } from './ui/button';
import { Slider } from './ui/slider';

const AlignMyAI = () => {
  const [layers, setLayers] = useState([4, 6, 5, 3]);
  const [nodeSize, setNodeSize] = useState(20);
  const [layerSpacing, setLayerSpacing] = useState(150);
  const [singleLayerMode, setSingleLayerMode] = useState(false);

  const width = window.innerWidth;
  const height = window.innerHeight - 200;

  const drawNetwork = () => {
    const elements = [];
    const maxNodesInLayer = Math.max(...layers);

    layers.forEach((nodeCount, layerIndex) => {
      const layerX = (layerIndex + 1) * layerSpacing;
      const layerHeight = nodeCount * (nodeSize * 2);
      const startY = (height - layerHeight) / 2 + nodeSize;

      for (let i = 0; i < nodeCount; i++) {
        const y = startY + i * (nodeSize * 2);

        // Draw node
        elements.push(
          <Circle
            key={`node-${layerIndex}-${i}`}
            x={layerX}
            y={y}
            radius={nodeSize}
            fill="#4299e1"
            stroke="#2b6cb0"
            strokeWidth={2}
          />
        );

        // Draw connections to next layer
        if (layerIndex < layers.length - 1) {
          const nextLayerX = (layerIndex + 2) * layerSpacing;
          const nextLayerNodeCount = layers[layerIndex + 1];
          const nextLayerHeight = nextLayerNodeCount * (nodeSize * 2);
          const nextLayerStartY = (height - nextLayerHeight) / 2 + nodeSize;

          for (let j = 0; j < nextLayerNodeCount; j++) {
            const nextY = nextLayerStartY + j * (nodeSize * 2);
            elements.push(
              <Line
                key={`connection-${layerIndex}-${i}-${j}`}
                points={[layerX + nodeSize, y, nextLayerX - nodeSize, nextY]}
                stroke="#a0aec0"
                strokeWidth={1}
              />
            );
          }
        }
      }

      // Layer label
      elements.push(
        <Text
          key={`label-${layerIndex}`}
          x={layerX - nodeSize}
          y={height - 230}
          text={layerIndex === 0 ? 'Input' : layerIndex === layers.length - 1 ? 'Output' : `Hidden ${layerIndex}`}
          fontSize={14}
          fill="#4a5568"
          align="center"
          width={nodeSize * 3}
        />
      );
    });

    return elements;
  };

  const handleAddLayer = () => {
    setLayers([...layers, 4]);
  };

  const handleRemoveLayer = () => {
    if (layers.length > 2) {
      setLayers(layers.slice(0, -1));
    }
  };

  const handleAddNode = (layerIndex) => {
    const newLayers = [...layers];
    newLayers[layerIndex] += 1;
    setLayers(newLayers);
  };

  const handleRemoveNode = (layerIndex) => {
    const newLayers = [...layers];
    if (newLayers[layerIndex] > 1) {
      newLayers[layerIndex] -= 1;
      setLayers(newLayers);
    }
  };

  const alignNetwork = () => {
    const totalNodes = layers.reduce((acc, count) => acc + count, 0);
    const newLayers = singleLayerMode ? [totalNodes] : Array(totalNodes).fill(1);
    setLayers(newLayers);
  };

  const saveNetworkToFile = () => {
    const networkData = JSON.stringify(layers);
    const blob = new Blob([networkData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'network.json';
    a.click();
    URL.revokeObjectURL(url);
  };

  const loadNetworkFromFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const networkData = JSON.parse(e.target.result);
          if (Array.isArray(networkData)) {
            setLayers(networkData);
            alert('Network loaded!');
          } else {
            alert('Invalid file format. Please upload a valid JSON file.');
          }
        } catch (error) {
          alert('Error reading file. Please ensure it is a valid JSON.');
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <div className="bg-gray-100 h-screen flex flex-col">
      <h1 className="text-4xl font-bold mb-6 text-center text-blue-800">Align My AI</h1>
      <div className="flex-grow overflow-auto" style={{ maxHeight: 'calc(100vh - 200px)' }}>
        <div style={{ width: layers.length * layerSpacing + 200, position: 'relative' }}>
          <Stage width={layers.length * layerSpacing + 200} height={height}>
            <Layer>{drawNetwork()}</Layer>
          </Stage>
          {layers.map((_, index) => (
            <div
              key={`layer-controls-${index}`}
              style={{
                position: 'absolute',
                left: (index + 1) * layerSpacing - nodeSize,
                top: height - 200,
                width: nodeSize * 2,
                textAlign: 'center',
              }}
            >
              <Button onClick={() => handleAddNode(index)} className="mb-1 flex items-center justify-center">
                <PlusIcon className="h-5 w-5 text-green-500" />
              </Button>
              <Button onClick={() => handleRemoveNode(index)} variant="outline" className="flex items-center justify-center">
                <MinusIcon className="h-5 w-5 text-red-500" />
              </Button>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center space-x-4 mb-6">
        <Button onClick={handleAddLayer} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center">
          <PlusIcon className="h-5 w-5 mr-2" /> Add Layer
        </Button>
        <Button onClick={handleRemoveLayer} variant="outline" className="bg-white hover:bg-gray-100 text-gray-800 font-bold py-2 px-4 rounded border border-gray-400 flex items-center">
          <MinusIcon className="h-5 w-5 mr-2" /> Remove Layer
        </Button>
      </div>
      <div className="flex justify-center mt-6">
        <Button onClick={alignNetwork} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Align My AI</Button>
      </div>
      <div className="flex justify-center mt-4">
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={singleLayerMode}
            onChange={() => setSingleLayerMode(!singleLayerMode)}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <span className="text-lg font-medium text-gray-700">Align to Single Layer</span>
        </label>
      </div>
      <div className="space-y-4 mt-4">
        <div className="flex justify-center space-x-4">
          <div>
            <label className="block text-lg font-medium text-gray-700 mb-1">Node Size</label>
            <Slider
              min={10}
              max={30}
              step={1}
              value={[nodeSize]}
              onValueChange={(value) => setNodeSize(value[0])}
            />
          </div>
          <div>
            <label className="block text-lg font-medium text-gray-700 mb-1">Layer Spacing</label>
            <Slider
              min={100}
              max={200}
              step={10}
              value={[layerSpacing]}
              onValueChange={(value) => setLayerSpacing(value[0])}
            />
          </div>
        </div>
        <div className="flex justify-center space-x-4 mt-4">
          <Button onClick={saveNetworkToFile} className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded flex items-center">
            <ArrowDownTrayIcon className="h-5 w-5 mr-2" /> Save AI to File
          </Button>
          <input
            type="file"
            accept=".json"
            onChange={loadNetworkFromFile}
            style={{ display: 'none' }}
            id="fileInput"
          />
          <label htmlFor="fileInput">
            <Button as="span" variant="outline" className="bg-white hover:bg-gray-100 text-gray-800 font-bold py-2 px-4 rounded border border-gray-400 flex items-center">
              <ArrowUpTrayIcon className="h-5 w-5 mr-2" /> Load AI from File
            </Button>
          </label>
        </div>
      </div>
    </div>
  );
};

export default AlignMyAI;